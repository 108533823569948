import type { StoreOptions } from 'vuex';
import { module } from './default.module';
import { analysisModule } from './analysis/analysis.module';
import { synchroModule } from './synchro/synchro.module';

export const storeOpts: StoreOptions<any> = {
  ...module,
  modules: {
    analysis: analysisModule,
    synchro: synchroModule,
  },
};
