import type { PieceFormat } from '@/formats/PieceParser';
import PieceParser from '@/formats/PieceParser';
import {
  type Audio, type EditableUnit, type Piece, type Source,
} from '@/data';
import UnitParser from '@/formats/UnitParser';
import type { Response } from './ClientResponse';
import { ClientResponse } from './ClientResponse';
import type { IClient } from './IClient';
import { loadPieceSources } from './sources';

export async function loadPiece(
  this: IClient,
  path: string,
  loadSources = true,
): Promise<Response<Piece>> {
  try {
    let pieceUrl;
    if (this.isDirectory) {
      pieceUrl = this.url(path, '/info.json').data;
    } else {
      pieceUrl = `${this.baseUrl}corpus/${path}?static=true`;
    }
    const format = (await this.get(pieceUrl)).data as PieceFormat;

    if (format.opus && format.opus['measure-map']) {
      try {
        if (typeof format.opus['measure-map'] === 'string') {
          if (format.opus['measure-map'].startsWith('/local-corpus')) {
            format.opus['measure-map'] = (await this.get(this.url(format.opus['measure-map'].replace('/local-corpus', '')).data)).data as string;
          } else if (format.opus['measure-map'].startsWith('http')) {
            format.opus['measure-map'] = (await this.get(format.opus['measure-map'])).data as string;
          } else {
            format.opus['measure-map'] = (await this.get(`${this.baseUrl}${this.staticRoute}/${path}/${format.opus['measure-map']}`)).data as string;
          }
        }
      } catch (error) {
        format.opus['measure-map'] = '';
      }
    }

    let sources: Source[];
    let tracks: Audio[];

    if (loadSources) {
      const sourceAndTracks = await loadPieceSources.bind(this)(format, path);
      sources = sourceAndTracks.sources;
      tracks = sourceAndTracks.tracks;
    } else {
      sources = [];
      tracks = [];
    }

    return this
      .tryParse(() => PieceParser.fromFormat(path, this.isDirectory, format, sources, tracks));
  } catch (e) {
    if (ClientResponse.isClientError(e)) return e.cast();
    return ClientResponse.fromParsingError(e as Error);
  }
}

export async function uploadSync(
  this: IClient,
  path: string,
  synchro: EditableUnit,
): Promise<Response<any>> {
  try {
    const url = `${this.baseUrl}corpus/${path}/synchro`;
    const obj = UnitParser.exportAudioFormat(synchro);
    const txt = JSON.stringify(obj, null, 2);
    const blob = new Blob([txt], { type: 'application/json' });
    const data = new FormData();
    data.append('synchro', blob);
    const response = (await this.put(url, data)).data;
    return this.tryParse(() => response);
  } catch (e) {
    if (ClientResponse.isClientError(e)) return e.cast();
    return ClientResponse.fromParsingError(e as Error);
  }
}
